<template>
  <el-container>
    <el-header height="220">
      <el-row>
        <el-select
          v-model="queryInfo.subjectId"
          clearable
          placeholder="请选择科目"
          style="margin-left: 5px"
          @change="subjectChange"
        >
          <el-option
            v-for="item in allSubject"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
        <el-select v-model="queryInfo.type" clearable placeholder="请选择试题类型" @change="typeChange">
          <el-option
            v-for="item in allType"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
        <el-input
          v-model="queryInfo.questionContent"
          placeholder="题目内容"
          style="margin-left: 5px;width: 220px"
          prefix-icon="el-icon-search"
          @blur="getQuestionInfo"
        />
      </el-row>
      <el-row style="margin-top: 10px">
        <el-button type="plain" icon="el-icon-plus" @click="addExamQuestion">添加</el-button>
      </el-row>
    </el-header>
    <el-main>
      <el-table
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          fixed="left"
          label="No"
          type="index"
        />
        <el-table-column
          prop="subject"
          label="所属科目"
        />
        <el-table-column
          prop="type"
          label="试题类型"
        />
        <el-table-column
          prop="content"
          label="试题内容"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.content"
              raw-content
              placement="top-start"
            >
              <span v-if="scope.row.content.length <= 15">
                <span v-html="scope.row.content" />
              </span>
              <span v-else>
                {{ scope.row.content.substr(0, 15) + "..." }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="level"
          label="试题难度"
        />
        <el-table-column
          prop="createBy"
          label="创建人"
        />
        <el-table-column
          prop="createAt"
          label="创建时间"
        />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="info"
              @click="viewDetail(scope.row.questionId)"
            >查看</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="onDeleteQuestion(scope.row.questionId)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 25px"
        :small="screenWidth <= 768"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>

    <el-dialog title="更新试题" :visible.sync="updateQuTableVisible" width="50%" center>
      <el-card>
        <el-form ref="updateQuForm" :model="updateQuForm" :rules="updateQuFormRules">
          <el-form-item label="试题类型" label-width="120px" prop="questionType">
            <el-select
              v-model="updateQuForm.questionType"
              disabled
              placeholder="请选择"
              @change="updateQuForm.answer = []"
            >
              <el-option
                v-for="item in questionType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="难度等级" label-width="120px" prop="questionLevel">
            <el-select v-model="updateQuForm.questionLevel" placeholder="请选择">
              <el-option :value="parseInt(1)" label="简单" />
              <el-option :value="parseInt(2)" label="中等" />
              <el-option :value="parseInt(3)" label="困难" />
            </el-select>
          </el-form-item>

          <el-form-item label="归属题库" label-width="120px" prop="subjectId">
            <el-select v-model="updateQuForm.subjectId" multiple placeholder="请选择">
              <el-option
                v-for="item in allSubject"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="试题内容" label-width="120px" prop="questionContent">
            <el-input
              v-model="updateQuForm.questionContent"
              style="margin-left: 5px"
              type="textarea"
              :rows="2"
            />
          </el-form-item>

          <el-form-item label="试题图片" label-width="120px">
            <el-upload
              :action="uploadImageUrl + '/teacher/uploadQuestionImage'"
              :on-preview="uploadPreview"
              :on-remove="handleUpdateRemove"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="updateUploadImgSuccess"
              name="file"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="试题解析" label-width="120px" prop="analysis">
            <el-input
              v-model="updateQuForm.analysis"
              style="margin-left: 5px"
              type="textarea"
              :rows="2"
            />
          </el-form-item>

          <el-button
            v-if="updateQuForm.questionType!==4"
            type="primary"
            plain
            size="small"
            icon="el-icon-plus"
            style="margin-left: 40px"
            @click="addUpdateAnswer"
          >
            添加
          </el-button>

          <!--存放答案表单的信息-->
          <el-form-item v-if="updateQuForm.questionType !== 4" prop="answer">
            <el-table :data="updateQuForm.answer" border style="width: 96%;margin-left: 40px;margin-top: 10px">

              <el-table-column label="是否答案" width="80" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.correct" @change="checked=>checkUpdateAnswer(checked,scope.row.id)">答案
                  </el-checkbox>
                </template>
              </el-table-column>

              <el-table-column label="答案图片">
                <template slot-scope="scope">
                  <el-upload
                    id="answerUpload"
                    :limit="1"
                    :action="uploadImageUrl + '/teacher/uploadQuestionImage'"
                    :on-preview="uploadPreview"
                    :on-remove="handleUpdateAnswerRemove"
                    :before-upload="beforeAvatarUpload"
                    list-type="picture"
                    :on-success="(res) => { return uploadUpdateAnswerImgSuccess(res,scope.row.id)}"
                    name="file"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </template>

              </el-table-column>

              <el-table-column label="答案内容">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.answer"
                    style="margin-left: 5px"
                    type="textarea"
                    :rows="2"
                  />
                </template>
              </el-table-column>

              <el-table-column label="答案解析">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.analysis"
                    style="margin-left: 5px"
                    type="textarea"
                    :rows="2"
                  />
                </template>
              </el-table-column>

              <el-table-column label="操作" width="80" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="delUpdateAnswer(scope.row.id)"
                  />
                </template>
              </el-table-column>

            </el-table>
          </el-form-item>

        </el-form>

      </el-card>

      <div slot="footer" class="dialog-footer">
        <el-button @click="updateQuTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateQuestion">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="试题详情" :visible.sync="questionDetailDialog" width="50%" center>
      <el-card>
        <el-form :model="questionDetail">
          <el-form-item label="所属科目" label-width="120px">
            <span> {{ questionDetail.subject }} </span>
          </el-form-item>
          <el-form-item label="试题类型" label-width="120px">
            <span> {{ questionDetail.typeStr }} </span>
          </el-form-item>
          <el-form-item label="试题难度" label-width="120px">
            <span> {{ questionDetail.level }} </span>
          </el-form-item>
          <el-divider />
          <el-form-item label="试题内容" label-width="120px">
            <span v-html="questionDetail.content" />
          </el-form-item>
          <el-form-item label="试题解析" label-width="120px">
            <span v-html="questionDetail.analysis" />
          </el-form-item>
          <el-divider />
          <el-form-item v-if="questionDetail.type <= 7" label="试题选项" label-width="120px">
            <el-table :data="questionDetail.options" border style="width: 96%;margin-left: 40px;margin-top: 10px">
              <el-table-column label="正确答案" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.correct">YES</span>
                  <span v-else>NO</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="option"
                label="选项"
              />
              <el-table-column
                prop="content"
                label="内容"
              />
              <el-table-column
                prop="analysis"
                label="解析"
              />
            </el-table>
          </el-form-item>

          <div v-if="questionDetail.children">
            <div v-for="item in questionDetail.children">
              <el-form :model="item">
                <el-form-item label="试题类型" label-width="120px">
                  <span> {{ item.typeStr }} </span>
                </el-form-item>
                <el-form-item label="试题难度" label-width="120px">
                  <span> {{ item.level }} </span>
                </el-form-item>
                <el-form-item label="试题内容" label-width="120px">
                  <span> {{ item.content }} </span>
                </el-form-item>
                <el-form-item label="试题解析" label-width="120px">
                  <span> {{ item.analysis }} </span>
                </el-form-item>
                <el-form-item v-if="item.type <= 7" label="试题选项" label-width="120px">
                  <el-table :data="item.options" border style="width: 96%;margin-left: 40px;margin-top: 10px">
                    <el-table-column label="正确答案" align="center">
                      <template slot-scope="scope">
                        <span v-if="scope.row.correct">YES</span>
                        <span v-else>NO</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="option"
                      label="选项"
                    />
                    <el-table-column
                      prop="content"
                      label="内容"
                    />
                    <el-table-column
                      prop="analysis"
                      label="解析"
                    />
                  </el-table>
                </el-form-item>
                <el-divider />
              </el-form>
            </div>
          </div>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="questionDetailDialog = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="加入题库"
      :visible.sync="addTableVisible"
      width="30%"
      center
      @close="resetAddForm"
    >

      <el-form ref="addForm" :model="addForm" :rules="addFormRules">

        <el-form-item label="题库名称" label-width="120px" prop="subjectId">

          <el-select v-model="addForm.subjectId" multiple placeholder="请选择题库">
            <el-option
              v-for="item in allSubject"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>

        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="addBank">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="从题库中移除"
      :visible.sync="removeTableVisible"
      width="30%"
      center
      @close="resetRemoveForm"
    >

      <el-form ref="removeForm" :model="removeForm" :rules="removeFormRules">
        <el-form-item label="题库名称" label-width="120px" prop="subjectId">
          <el-select v-model="removeForm.subjectId" multiple placeholder="请选择题库">
            <el-option
              v-for="item in allSubject"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>

        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="removeTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="removeBank">确 定</el-button>
      </div>
    </el-dialog>

    <!--图片回显-->
    <el-dialog :visible.sync="backShowImgVisible" @close="backShowImgVisible = false">
      <img style="width: 100%" :src="backShowImgUrl" alt="">
    </el-dialog>
  </el-container>
</template>

<script>
import {
  getSubjectKV,
  addQuestion,
  getQuestion1,
  deleteQuestion,
  getQuestions, getQuestionType, getQuestion
} from '@/api/exam'
import { validFormAndInvoke } from '@/utils/util'

export default {
  name: 'ExamQuestion',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 20,
      totalSize: 0,
      dataList: [],
      // **********************************************************************
      uploadImageUrl: '/',
      // 查询用户的参数
      queryInfo: {
        subjectId: null,
        type: null,
        level: null,
        pageNumber: 1,
        pageSize: 10
      },
      // 试题类型
      questionType: [
        {
          id: 1,
          name: '单选题'
        },
        {
          id: 2,
          name: '多选题'
        },
        {
          id: 3,
          name: '不定项选择题'
        },
        {
          id: 4,
          name: '判断题'
        },
        {
          id: 5,
          name: '填空题'
        },
        {
          id: 6,
          name: '问答题'
        },
        {
          id: 7,
          name: '理解题'
        },
        {
          id: 8,
          name: '組题'
        }
      ],
      // 题库信息
      allSubject: [],
      allType: [],
      // 试题信息
      questionInfo: [],
      // 试题信息表格是否加载
      loading: true,
      // 表格被选中的所有行
      selectionTable: [],
      // 表格行被选中后的所有操作方式的数据
      optionInfo: [
        {
          'label': '删除',
          'desc': 'delete'
        },
        {
          'label': '加入题库',
          'desc': 'add'
        },
        {
          'label': '题库中移除',
          'desc': 'remove'
        }
      ],
      // 表格行被选中后的数据
      operation: '',
      // 试题总数
      total: 0,
      // 是否显示加入题库对话框
      addTableVisible: false,
      // 是否显示移除题库对话框
      removeTableVisible: false,
      // 是否显示添加试题的对话框
      addQuTableVisible: false,
      // 添加题库的表单信息
      addForm: {
        subjectId: ''
      },
      removeForm: {
        subjectId: ''
      },
      // 添加题库表单的验证
      addFormRules: {
        subjectId: [
          {
            required: true,
            message: '请选择需要添加进的题库',
            trigger: 'blur'
          }
        ]
      },
      // 移除题库表单的验证
      removeFormRules: {
        subjectId: [
          {
            required: true,
            message: '请选择需要移除的题库',
            trigger: 'blur'
          }
        ]
      },
      // 添加试题的表单信息
      addQuForm: {
        questionType: 1,
        questionLevel: 1,
        subjectId: '',
        questionContent: '',
        images: [],
        analysis: '',
        createPerson: localStorage.getItem('username'),
        // 答案对象
        options: [],
        children: []
      },
      questionForm: {
        questionType: 1,
        questionLevel: 1,
        subjectId: '',
        questionContent: '',
        images: [],
        analysis: '',
        // 答案对象
        options: []
      },
      // 添加试题表单的验证规则
      addQuFormRules: {
        questionType: [
          {
            required: true,
            message: '请选择问题类型',
            trigger: 'blur'
          }
        ],
        questionLevel: [
          {
            required: true,
            message: '请选择问题难度',
            trigger: 'blur'
          }
        ],
        subjectId: [
          {
            required: true,
            message: '请选择题库',
            trigger: 'blur'
          }
        ],
        questionContent: [
          {
            required: true,
            message: '请输入题库内容',
            trigger: 'blur'
          }
        ]
      },
      // 更新试题表单的验证规则
      updateQuFormRules: {
        questionType: [
          {
            required: true,
            message: '请选择问题类型',
            trigger: 'blur'
          }
        ],
        questionLevel: [
          {
            required: true,
            message: '请选择问题难度',
            trigger: 'blur'
          }
        ],
        subjectId: [
          {
            required: true,
            message: '请选择题库',
            trigger: 'blur'
          }
        ],
        questionContent: [
          {
            required: true,
            message: '请输入题库内容',
            trigger: 'blur'
          }
        ]
      },
      // 图片回显的样式
      backShowImgVisible: false,
      // 图片回显的图片地址
      backShowImgUrl: '',
      // 更新试题的数据信息
      updateQuForm: {
        questionId: '',
        questionType: 1,
        questionLevel: 1,
        subjectId: '',
        questionContent: '',
        images: [],
        analysis: '',
        createPerson: localStorage.getItem('username'),
        // 答案对象
        answer: []
      },
      // 是否显示更新试题的对话框
      updateQuTableVisible: false,
      questionDetailDialog: false,
      questionDetail: {}
    }
  },
  created() {
    document.title = '题库管理'
    this.getSubjects()
    this.getQuestionTypes()
    this.getData(this.searchForm)
  },
  methods: {
    renderByMathjax() {
      // tinymce 的 mathjax 插件生成的 latex 格式公式放在 className 为 math-tex 的 span 标签内
      const className = 'math-tex'
      this.$nextTick(function() {
        if (this.MathJax.isMathjaxConfig) {
          this.MathJax.initMathjaxConfig()
        }
        this.MathJax.MathQueue1(className)
      })
    },
    getData() {
      this.dataList = []
      getQuestions(this.queryInfo).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    getQuestionTypes() {
      getQuestionType().then((resp) => {
        if (resp.code === 0) {
          this.allType = resp.data
        } else {
          this.$notify({
            title: 'Tips',
            message: resp.message,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 获取所有的题库信息
    getSubjects() {
      getSubjectKV().then((resp) => {
        if (resp.code === 0) {
          this.allSubject = resp.data
        }
      })
    },
    // 试题类型变化
    typeChange(val) {
      this.queryInfo.type = val
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getQuestionInfo()
    },
    // 题库变化
    subjectChange(val) {
      this.queryInfo.subjectId = val
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getQuestionInfo()
    },
    // 试题名字筛选
    contentChange() {
      // 发送查询试题总数的请求
      this.getQuestionInfo()
    },
    // 获取试题信息
    getQuestionInfo() {
      this.getData()
    },
    // 处理表格被选中
    handleTableSectionChange(val) {
      this.selectionTable = val
    },
    // 处理操作选择框的变化
    operationChange(val) {
      // 清空上一次的选择
      this.operation = ''

      const questionIds = []
      if (val === 'delete') {
        this.selectionTable.map(item => {
          questionIds.push(item.id)
        })
        // 发起删除请求
        deleteQuestion({ 'questionIds': questionIds.join(',') }).then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: 'Tips',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.getQuestionInfo()
          }
        })
      } else if (val === 'add') {
        this.addTableVisible = true
      } else if (val === 'remove') {
        this.removeTableVisible = true
      }
    },
    // 分页页面大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getQuestionInfo()
    },
    // 分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val
      this.getQuestionInfo()
    },
    // 表单信息重置
    resetAddForm() {
      // 清空表格数据
      this.$refs['addForm'].resetFields()
    },
    resetRemoveForm() {
      // 清空表格数据
      this.$refs['removeForm'].resetFields()
    },
    resetAddQuForm() {
      this.$refs['addQuForm'].resetFields()
    },
    // 提交加入题库的表单信息
    addBank() {
      validFormAndInvoke(this.$refs['addForm'], () => {
        const questionIds = []
        const banks = this.addForm.subjectId
        // 将表格选中的数据中的问题id加入进去
        this.selectionTable.map(item => {
          questionIds.push(item.id)
        })
        questionBank.addBankQuestion({
          'questionIds': questionIds.join(','),
          'banks': banks.join(',')
        }).then((resp) => {
          if (resp.code === 0) {
            this.getQuestionInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请选择需要加入进的题库')
    },
    // 提交移除题库的表单信息
    removeBank() {
      validFormAndInvoke(this.$refs['removeForm'], () => {
        const questionIds = []
        const banks = this.removeForm.subjectId
        // 将表格选中的数据中的问题id加入进去
        this.selectionTable.map(item => {
          questionIds.push(item.id)
        })
        // 发起移除请求
        questionBank.removeBankQuestion({
          'questionIds': questionIds.join(','),
          'banks': banks.join(',')
        }).then((resp) => {
          if (resp.code === 0) {
            this.getQuestionInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          }
          this.removeTableVisible = false
        })
      }, '请选择需要移除的题库')
    },
    // 新增试题上传后 点击图片的回显
    uploadPreview(file) {
      this.backShowImgUrl = file.response.data
      this.backShowImgVisible = true
    },
    // 新增试题中的上传图片的移除
    handleRemove(file, fileList) {
      this.addQuForm.images.map((item, index) => { // 移除图片在表单中的数据
        if (item === file.response.data) this.addQuForm.images.splice(index, 1)
      })
    },
    // 更新试题中的上传图片的移除
    handleUpdateRemove(file, fileList) {
      this.updateQuForm.images.map((item, index) => { // 移除图片在表单中的数据
        if (item === file.response.data) this.updateQuForm.images.splice(index, 1)
      })
    },
    // 新增试题中的上传图片的格式大小限制
    beforeAvatarUpload(file) {
      const isImg = file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt = file.size / 1024 / 1024 < 10

      if (!isImg) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
      }

      if (!isLt) {
        this.$message.error('上传头像图片大小不能超过 10MB!')
      }
      return isImg && isLt
    },
    // 新增试题中的上传图片成功后的钩子函数
    uploadImgSuccess(response, file, fileList) {
      this.addQuForm.images.push(response.data)
    },
    // 更新试题中的上传图片成功后的钩子函数
    updateUploadImgSuccess(response, file, fileList) {
      this.updateQuForm.images.push(response.data)
    },
    // 新增试题中的新增答案填写框
    addAnswer() {
      this.addQuForm.options.push({
        id: this.addQuForm.options.length,
        correct: false,
        content: '',
        images: [],
        analysis: ''
      })
    },
    // 更新时新增试题中的新增答案填写框
    addUpdateAnswer() {
      this.updateQuForm.answer.push({
        id: this.updateQuForm.answer.length,
        correct: false,
        answer: '',
        images: [],
        analysis: ''
      })
    },
    // 新增试题中的删除答案填写框
    delAnswer(id) { // 当前答案的id
      this.addQuForm.options.map((item, index) => {
        if (item.id === id) this.addQuForm.options.splice(index, 1)
      })
    },
    // 新增试题中的删除答案填写框
    delUpdateAnswer(id) { // 当前答案的id
      this.updateQuForm.answer.map((item, index) => {
        if (item.id === id) this.updateQuForm.answer.splice(index, 1)
      })
    },
    // 答案上传照片了
    uploadAnswerImgSuccess(response, id) {
      this.addQuForm.options[id].images.push(response.data)
    },
    // 更新的答案上传图片了
    uploadUpdateAnswerImgSuccess(response, id) {
      this.updateQuForm.answer[id].images.push(response.data)
    },
    // 答案上传成功后删除
    handleAnswerRemove(file) {
      this.addQuForm.options.images.map((item, index) => { // 移除图片在表单中的数据
        if (item === file.response.data) this.addQuForm.images.splice(index, 1)
      })
    },
    // 更新的时候答案上传成功后删除
    handleUpdateAnswerRemove(file) {
      this.updateQuForm.answer.images.map((item, index) => { // 移除图片在表单中的数据
        if (item === file.response.data) this.updateQuForm.images.splice(index, 1)
      })
    },
    // 选择正确答案的按钮变化事件
    checkAnswer(checked, id) {
      if (checked) {
        if (this.addQuForm.questionType === 1 || this.addQuForm.questionType === 3) { // 单选或者判断
          // 当前已经有一个正确的选择了
          this.addQuForm.options.map(item => {
            item.correct = false
          })
          this.addQuForm.options.map(item => {
            if (item.id === id) item.correct = true
          })
        } else { // 多选 可以有多个答案
          this.addQuForm.options.map(item => {
            if (item.id === id) item.correct = true
          })
        }
      } else {
        this.addQuForm.options.map(item => {
          if (item.id === id) item.correct = false
        })
      }
    },
    // 更新时选择正确答案的按钮变化事件
    checkUpdateAnswer(checked, id) {
      if (checked) {
        if (this.updateQuForm.questionType === 1 || this.updateQuForm.questionType === 3) { // 单选或者判断
          // 当前已经有一个正确的选择了
          this.updateQuForm.answer.map(item => {
            item.correct = false
          })
          this.updateQuForm.answer.map(item => {
            if (item.id === id) item.correct = true
          })
        } else { // 多选 可以有多个答案
          this.updateQuForm.answer.map(item => {
            if (item.id === id) item.correct = true
          })
        }
      } else {
        this.updateQuForm.answer.map(item => {
          if (item.id === id) item.correct = false
        })
      }
    },
    // 新增试题
    addQuestion() {
      this.$refs['addQuForm'].validate((valid) => {
        if (valid && this.addQuForm.options.some(item => item.correct) && this.addQuForm.questionType !== 4) { // 单选或者多选或者判断
          addQuestion(this.addQuForm).then((resp) => {
            if (resp.code === 0) {
              this.addQuTableVisible = false
              this.getData(this.searchForm)
              this.$notify({
                title: 'Tips',
                message: '新增试题成功',
                type: 'success',
                duration: 2000
              })
            }
          })
        } else if (valid && !this.addQuForm.options.some(item => item.correct) && this.addQuForm.questionType < 5) { // 无答案
          this.$message.error('必须有一个答案')
          return false
        } else if (valid && this.addQuForm.questionType >= 5 && this.addQuForm.questionType <= 7) { // 简答题 无标准答案直接发请求
          // 当是简答题的时候需要清除answer
          this.addQuForm.options = []
          addQuestion(this.addQuForm).then((resp) => {
            if (resp.code === 0) {
              this.addQuTableVisible = false
              this.getQuestionInfo()
              this.$notify({
                title: 'Tips',
                message: '新增试题成功',
                type: 'success',
                duration: 2000
              })
            }
          })
        } else if (this.addQuForm.questionType > 7) { // 組题
          // 当是简答题的时候需要清除answer
          this.addQuForm.options = []
          addQuestion(this.addQuForm).then((resp) => {
            if (resp.code === 0) {
              this.addQuTableVisible = false
              this.getQuestionInfo()
              this.$notify({
                title: 'Tips',
                message: '新增试题成功',
                type: 'success',
                duration: 2000
              })
            }
          })
        } else if (!valid) {
          this.$message.error('请填写必要信息')
          return false
        }
      })
    },
    // 更新试题
    updateQu(id) {
      getQuestionById(id).then((resp) => {
        if (resp.code === 0) {
          if (resp.data.questionType !== 4) {
            resp.data.answer.map(item => {
              item.correct = item.correct === 'true'
            })
          }
          this.updateQuForm = resp.data
          // 处理图片那个参数是个数组
          if (this.updateQuForm.images === null) this.updateQuForm.images = []

          if (resp.data.questionType !== 4) {
            this.updateQuForm.answer.map(item => {
              if (item.images === null) {
                item.images = []
              }
            })
          }
        }
      })
      this.updateQuTableVisible = true
    },
    // 提交更新表单
    updateQuestion() {
      this.$refs['updateQuForm'].validate((valid) => {
        if (valid && this.updateQuForm.questionType !== 4 && this.updateQuForm.answer.some(item => item.correct)) { // 单选或者多选或者判断
          // 保证答案的图片只有一张
          this.updateQuForm.answer.map(item => {
            if (item.images.length > 1) {
              item.images.splice(0, item.images.length - 1)
            }
          })
          updateQuestion(this.updateQuForm).then((resp) => {
            if (resp.code === 0) {
              this.updateQuTableVisible = false
              this.getQuestionInfo()
              this.$notify({
                title: 'Tips',
                message: '更新试题成功',
                type: 'success',
                duration: 2000
              })
            }
          })
        } else if (valid && this.updateQuForm.questionType !== 4 && !this.updateQuForm.answer.some(item => item.correct)) { // 无答案
          this.$message.error('必须有一个答案')
          return false
        } else if (valid && this.updateQuForm.questionType === 4) { // 简答题 无标准答案直接发请求
          // 当是简答题的时候需要清除answer
          this.addQuForm.options = []
          updateQuestion(this.updateQuForm).then((resp) => {
            if (resp.code === 0) {
              this.updateQuTableVisible = false
              this.getQuestionInfo()
              this.$notify({
                title: 'Tips',
                message: '更新试题成功',
                type: 'success',
                duration: 2000
              })
            }
          })
        } else if (!valid) {
          this.$message.error('请填写必要信息')
          return false
        }
      })
    },
    viewDetail(questionId) {
      getQuestion(questionId).then(resp => {
        if (resp.code === 0) {
          this.questionDetail = resp.data
          this.questionDetailDialog = true
          this.renderByMathjax()
        } else {
          this.$notify({
            title: 'Tips',
            message: resp.msg,
            type: 'success',
            duration: 2000
          })
        }
      })
    },
    onDeleteQuestion(questionId) {
      deleteQuestion(questionId).then(resp => {
        if (resp.code === 0) {
          this.$notify({
            title: 'Tips',
            message: '试题 ' + questionId + ' 已删除',
            type: 'success',
            duration: 1000
          })
          window.location.reload()
        } else {
          this.$notify({
            title: 'Error',
            message: resp.msg,
            type: 'error',
            duration: 1000
          })
        }
      })
    },
    addExamQuestion() {
      this.$router.push('/exam/question/add')
    }
  }
}
</script>

<style>
</style>
